var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Wrap" },
    [
      _c("div", { staticClass: "Header" }, [
        _c("div", { staticClass: "Nav" }, [
          _vm.menuGubun == "02"
            ? _c("ul", [
                _vm.hasAuthority(["/viewAccidentPrevent.do"])
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { "data-desc": "재해예방조치" },
                          on: {
                            click: function($event) {
                              return _vm.loadView("accidentPrevent", $event)
                            }
                          }
                        },
                        [_c("span", [_vm._v("재해예방조치")])]
                      )
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewDangerWarnWorkOccur.do",
                  "/aiDistinguish.do"
                ])
                  ? _c("li", [
                      _vm._m(0),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewDangerWarnWorkOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "발생이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "dangerWarnWorkOccur",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("발생이력")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/aiDistinguish.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { "data-desc": "AI판별이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "aiDistinguish",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("AI판별이력")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewTree.do",
                  "/viewSiteStatistic.do",
                  "/viewAccidentStatistic.do",
                  "/viewObjectDangerOccurStatistic.do",
                  "/viewObjectDangerOccurStatisticBar.do",
                  "/viewObjectDangerOccurStatisticLine2.do",
                  "/viewObjectDangerOccurStatisticBar2.do"
                ])
                  ? _c("li", [
                      _vm._m(1),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewTree.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "현장별사용자조회" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("tree", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장별 사용자 조회")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSiteStatistic.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "현장별통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "siteStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장별통계")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewAccidentStatistic.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "재해유형별통계" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "accidentStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("재해유형별통계")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatistic.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "상황별 발생 추이" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("상황별 발생 추이")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticBar.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "상황별 발생 건수" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticBar",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("상황별 발생 건수")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticLine2.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "상황별 발생 추이(본사)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticLine2",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("상황별 발생 추이(본사)")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticBar2.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "상황별 발생 건수(본사)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticBar2",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("상황별 발생 건수(본사)")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority(["/viewExemplaryCase.do"])
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { "data-desc": "모범사례" },
                          on: {
                            click: function($event) {
                              return _vm.loadView("exemplaryCase", $event)
                            }
                          }
                        },
                        [_c("span", [_vm._v("모범사례")])]
                      )
                    ])
                  : _vm._e(),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasAuthority([
                          "/viewCompany.do",
                          "/viewSite.do",
                          "/viewUser.do",
                          "/viewRegisterUser.do",
                          "/viewSettingPushAlarm.do",
                          "/viewNotice.do",
                          "/viewMenu.do",
                          "/viewRoleGroup.do",
                          "/viewGrpCode.do",
                          "/viewCommCode.do",
                          "/viewApiCallErrLog.do",
                          "/viewObject.do",
                          "/viewTarget.do",
                          "/viewObjectTarget.do",
                          "/viewWarnWork.do",
                          "/viewBasicAiConfig.do",
                          "/viewAiConfigDistigshSutbDgreCctv.do",
                          "/viewCctvDangerAreaConfig.do",
                          "/viewCctvAiConfig.do",
                          "/viewSpeakerAiConfig.do",
                          "/viewCctv.do",
                          "/viewSpeaker.do",
                          "/viewSpeakerSoundsrc.do",
                          "/viewSpeakerCctv.do",
                          "/viewCctvBySafeManager.do"
                        ]),
                        expression:
                          "\n\t\t\t\t\t\thasAuthority([\n\t\t\t\t\t\t\t'/viewCompany.do',\n\t\t\t\t\t\t\t'/viewSite.do',\n\t\t\t\t\t\t\t'/viewUser.do',\n\t\t\t\t\t\t\t'/viewRegisterUser.do',\n\t\t\t\t\t\t\t'/viewSettingPushAlarm.do',\n\t\t\t\t\t\t\t'/viewNotice.do',\n\t\t\t\t\t\t\t'/viewMenu.do',\n\t\t\t\t\t\t\t'/viewRoleGroup.do',\n\t\t\t\t\t\t\t'/viewGrpCode.do',\n\t\t\t\t\t\t\t'/viewCommCode.do',\n\t\t\t\t\t\t\t'/viewApiCallErrLog.do',\n\t\t\t\t\t\t\t'/viewObject.do',\n\t\t\t\t\t\t\t'/viewTarget.do',\n\t\t\t\t\t\t\t'/viewObjectTarget.do',\n\t\t\t\t\t\t\t'/viewWarnWork.do',\n\t\t\t\t\t\t\t'/viewBasicAiConfig.do',\n\t\t\t\t\t\t\t'/viewAiConfigDistigshSutbDgreCctv.do',\n\t\t\t\t\t\t\t'/viewCctvDangerAreaConfig.do',\n\t\t\t\t\t\t\t'/viewCctvAiConfig.do',\n\t\t\t\t\t\t\t'/viewSpeakerAiConfig.do',\n\t\t\t\t\t\t\t'/viewCctv.do',\n\t\t\t\t\t\t\t'/viewSpeaker.do',\n\t\t\t\t\t\t\t'/viewSpeakerSoundsrc.do',\n\t\t\t\t\t\t\t'/viewSpeakerCctv.do',\n\t\t\t\t\t\t\t'/viewCctvBySafeManager.do',\n\t\t\t\t\t\t])\n\t\t\t\t\t"
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          mouseover: function($event) {
                            return _vm.setSubMenuLocation()
                          }
                        }
                      },
                      [_c("span", [_vm._v("관리")])]
                    ),
                    _c(
                      "div",
                      {
                        ref: "navSubMultiple",
                        staticClass: "Nav_Sub_Multiple"
                      },
                      [
                        _vm.hasAuthority([
                          "/viewCompany.do",
                          "/viewSite.do",
                          "/viewUser.do",
                          "/viewRegisterUser.do"
                        ])
                          ? _c("div", { staticClass: "mng_menu" }, [
                              _c("ul", [
                                _vm._m(2),
                                _vm.hasAuthority(["/viewCompany.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "업체관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "company",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("업체관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSite.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "현장관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "site",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("현장관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewUser.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "사용자관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "user",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("사용자관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewRegisterUser.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "사용자가입승인"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "registerUser",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("사용자가입승인")])]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewNotice.do",
                          "/viewMenu.do",
                          "/viewRoleGroup.do",
                          "/viewGrpCode.do",
                          "/viewCommCode.do",
                          "/viewApiCallErrLog.do",
                          "/viewSettingPushAlarm.do"
                        ])
                          ? _c("div", { staticClass: "mng_menu" }, [
                              _c("ul", [
                                _vm._m(3),
                                _vm.hasAuthority(["/viewNotice.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "공지사항" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "notice",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("공지사항")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewMenu.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "메뉴코드관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "menu",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("메뉴코드관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewRoleGroup.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "권한그룹관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "roleGroup",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("권한그룹관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewGrpCode.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "공통그룹코드관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "grpCode",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("공통그룹코드관리")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewCommCode.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "공통코드관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "commCode",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("공통코드관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewApiCallErrLog.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "API호출오류로그조회"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "apiCallErrLog",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("API호출오류로그조회")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSettingPushAlarm.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "푸시알림관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "settingPushAlarm",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("푸시알림관리")])]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewObject.do",
                          "/viewTarget.do",
                          "/viewObjectTarget.do",
                          "/viewWarnWork.do"
                        ])
                          ? _c("div", { staticClass: "mng_menu" }, [
                              _c("ul", [
                                _vm._m(4),
                                _vm.hasAuthority(["/viewObject.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "대상물관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "object",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("대상물관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewTarget.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "조치대상관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "target",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("조치대상관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewObjectTarget.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "대상물조치대상관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "objectTarget",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("대상물조치대상관리")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewWarnWork.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "위험대상물조합관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "warnWork",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("위험대상물조합관리")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewBasicAiConfig.do",
                          "/viewAiConfigDistigshSutbDgreCctv.do",
                          "/viewCctvDangerAreaConfig.do",
                          "/viewSiteTrespassConfig.do",
                          "/viewCctvAiConfig.do",
                          "/viewSpeakerAiConfig.do"
                        ])
                          ? _c("div", { staticClass: "mng_menu" }, [
                              _c("ul", [
                                _vm._m(5),
                                _vm.hasAuthority(["/viewCctvAiConfig.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "CCTV 대상물 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "cctvAiConfig",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("CCTV 대상물 설정")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewBasicAiConfig.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "위험 판단 기준 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "basicAiConfig",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("위험 판단 기준 설정")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewAiConfigDistigshSutbDgreCctv.do"
                                ])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "AI-threshold 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "aiConfigDistigshSutbDgreCctv",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("AI-threshold 설정")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority([
                                  "/viewCctvDangerAreaConfig.do"
                                ])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "위험지역 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "cctvDangerAreaConfig",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("위험지역 설정")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSiteTrespassConfig.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "침입탐지 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "siteTrespassConfig",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("침입탐지 설정")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSpeakerAiConfig.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "스피커대상물 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "speakerAiConfig",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("스피커대상물 설정")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewCctv.do",
                          "/viewSpeaker.do",
                          "/viewSpeakerSoundsrc.do",
                          "/viewSpeakerCctv.do",
                          "/viewCctvBySafeManager.do"
                        ])
                          ? _c("div", { staticClass: "mng_menu" }, [
                              _c("ul", [
                                _vm._m(6),
                                _vm.hasAuthority(["/viewCctv.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "CCTV관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "cctv",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("CCTV관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewCctvBySafeManager.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "CCTV 설치장소 변경"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "cctvBySafeManager",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("CCTV 설치장소 변경")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSpeaker.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "data-desc": "스피커관리" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "speaker",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("스피커관리")])]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSpeakerCctv.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "스피커-CCTV 관계 설정"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "speakerCctv",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("스피커-CCTV 관계 설정")
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.hasAuthority(["/viewSpeakerSoundsrc.do"])
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            "data-desc": "스피커음원관리"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadView(
                                                "speakerSoundsrc",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_c("span", [_vm._v("스피커음원관리")])]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _c("li", [
                  _vm._m(7),
                  _c("div", { staticClass: "Nav_Sub" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              "data-desc": "dashboard",
                              target: "_blank"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadView("dashboard", $event)
                              }
                            }
                          },
                          [_c("span", [_vm._v("현장 모니터링")])]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.menuGubun == "01"
            ? _c("ul", [
                _vm.hasAuthority([
                  "/viewDangerOccur.do",
                  "/viewWarnWorkOccur.do",
                  "/viewObjectDangerOccurStatistic.do",
                  "/viewObjectDangerOccurStatisticBar.do",
                  "/viewObjectDangerOccurStatisticLine2.do",
                  "/viewObjectDangerOccurStatisticBar2.do"
                ])
                  ? _c("li", [
                      _vm._m(8),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewDangerOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "위험발생이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "dangerOccur",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험발생이력")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewDangerOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "위험발생이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "dangerOccurVideo",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험발생이력-비디오")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "주의작업이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "warnWorkOccur",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("주의작업이력")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "주의작업이력" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "warnWorkOccurVideo",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("주의작업이력-비디오")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewTree.do",
                  "/viewObjectDangerOccurStatistic.do"
                ])
                  ? _c("li", [
                      _vm._m(9),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewTree.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "현장별사용자조회" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("tree", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장별 사용자 조회")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatistic.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "상황별 발생 추이" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatistic",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("상황별 발생 추이")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticBar.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "상황별 발생 건수" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticBar",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("상황별 발생 건수")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticLine2.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "상황별 발생 추이(본사)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticLine2",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("상황별 발생 추이(본사)")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewObjectDangerOccurStatisticBar2.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "상황별 발생 건수(본사)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectDangerOccurStatisticBar2",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("상황별 발생 건수(본사)")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewNotice.do",
                  "/viewCompany.do",
                  "/viewSite.do",
                  "/viewUser.do",
                  "/viewMenu.do",
                  "/viewRoleGroup.do"
                ])
                  ? _c("li", [
                      _vm._m(10),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewNotice.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "공지사항" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("notice", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("공지사항")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCompany.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "업체관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("company", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("업체관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSite.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "현장관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("site", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("현장관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewUser.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "사용자관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("user", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("사용자관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewRegisterUser.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "사용자가입승인" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "registerUser",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("사용자가입승인")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewMenu.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "메뉴코드관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("menu", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("메뉴코드관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewRoleGroup.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "권한그룹관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("roleGroup", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("권한그룹관리")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.hasAuthority([
                  "/viewGrpCode.do",
                  "/viewCommCode.do",
                  "/viewObject.do",
                  "/viewTarget.do",
                  "/viewObjectTarget.do",
                  "/viewWarnWork.do",
                  "/viewCctv.do",
                  "/viewBasicAiConfig.do",
                  "/viewCctvAiConfig.do",
                  "/viewApiCallErrLog.do",
                  "/viewCctvDangerAreaConfig.do"
                ])
                  ? _c("li", [
                      _vm._m(11),
                      _c("div", { staticClass: "Nav_Sub" }, [
                        _c("ul", [
                          _vm.hasAuthority(["/viewGrpCode.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "공통그룹코드관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("grpCode", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("공통그룹코드관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCommCode.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "공통코드관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("commCode", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("공통코드관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewObject.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "대상물관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("object", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("대상물관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewTarget.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "조치대상관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("target", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("조치대상관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewObjectTarget.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "대상물조치대상관리"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "objectTarget",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("대상물조치대상관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewWarnWork.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "위험대상물조합관리"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("warnWork", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험대상물조합관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCctv.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "CCTV관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("cctv", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("CCTV관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCctvAiConfig.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "CCTV 대상물 설정" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "cctvAiConfig",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("CCTV 대상물 설정")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewBasicAiConfig.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "위험 판단 기준 설정"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "basicAiConfig",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험 판단 기준 설정")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewApiCallErrLog.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "API호출오류로그조회"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "apiCallErrLog",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("API호출오류로그조회")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCctvDangerAreaConfig.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "위험지역 설정" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "cctvDangerAreaConfig",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("위험지역 설정")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSiteTrespassConfig.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "침입탐지 설정" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "siteTrespassConfig",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("침입탐지 설정")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSpeaker.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "스피커관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView("speaker", $event)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("스피커관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSpeakerSoundsrc.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "스피커음원관리" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "speakerSoundsrc",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("스피커음원관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSpeakerCctv.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "스피커-CCTV 관계 설정"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "speakerCctv",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("스피커-CCTV 관계 설정")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewSpeakerAiConfig.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "스피커대상물 설정" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "speakerAiConfig",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("스피커대상물 설정")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority(["/viewCctvBySafeManager.do"])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "data-desc": "CCTV 설치장소 관리"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "cctvBySafeManager",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("CCTV 설치장소 관리")])]
                                )
                              ])
                            : _vm._e(),
                          _vm.hasAuthority([
                            "/viewAiConfigDistigshSutbDgreCctv.do"
                          ])
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "data-desc": "AI-threshold 설정" },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadView(
                                          "aiConfigDistigshSutbDgreCctv",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("AI-threshold 설정")])]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { "data-desc": "dashboard", target: "_blank" },
                      on: {
                        click: function($event) {
                          return _vm.loadView("dashboard", $event)
                        }
                      }
                    },
                    [_c("span", [_vm._v("모니터링")])]
                  )
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "Login" }, [
          _vm.loginUserInfo.userRoleCd == "00"
            ? _c(
                "a",
                {
                  staticClass: "Guide_Download",
                  attrs: {
                    href: "/download/사용자매뉴얼_본사관리자용.pdf",
                    download: ""
                  }
                },
                [_vm._v(" 매뉴얼 다운로드 ")]
              )
            : _vm._e(),
          _vm.loginUserInfo.userRoleCd == "01"
            ? _c(
                "a",
                {
                  staticClass: "Guide_Download",
                  attrs: {
                    href: "/download/사용자매뉴얼_안전관리자용.pdf",
                    download: ""
                  }
                },
                [_vm._v(" 매뉴얼 다운로드 ")]
              )
            : _vm._e(),
          _c("button", [
            _c("img", { attrs: { src: "images/new-main/Profile.png" } }),
            _c("span", [_vm._v(_vm._s(_vm.user.id))]),
            _c(
              "div",
              {
                staticClass: "Login_Sub",
                style:
                  "" +
                  (_vm.loginUserInfo.userRoleCd === "04" ? "right: 188px" : "")
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.logout()
                      }
                    }
                  },
                  [_vm._v("로그아웃")]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.changeSiteId()
                      }
                    }
                  },
                  [_vm._v("현장변경")]
                )
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "Container" }, [
        _c("div", { staticClass: "Le_Menu" }, [
          _c("div", { staticClass: "Le_Menu_Ti" }, [
            _c("p", [_vm._v(_vm._s(_vm.user.site.name))]),
            _c("p", [_vm._v(_vm._s(_vm.nowDate))])
          ]),
          _c("div", { staticClass: "Weather" }, [
            _c("div", { staticClass: "Weather_Top" }, [
              _c("div", { staticClass: "Weather_Left" }, [
                _vm._m(12),
                _c("div", { staticStyle: { "padding-left": "12px" } }, [
                  _vm.weather.today.temperature >= "5"
                    ? _c("span", { staticClass: "Red" }, [
                        _vm._v(_vm._s(_vm.weather.today.temperature) + "˚")
                      ])
                    : _vm._e(),
                  _vm.weather.today.temperature < "5"
                    ? _c("span", { staticClass: "Blue" }, [
                        _vm._v(_vm._s(_vm.weather.today.temperature) + "˚")
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "Weather_Right" }, [
                _c("div", [
                  _c("span", [_vm._v("습도")]),
                  _c("span", [_vm._v(_vm._s(_vm.weather.today.humidity))])
                ]),
                _c("div", [
                  _c("span", [_vm._v("풍속")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.weather.today.windSpeed) + "m")
                  ])
                ]),
                _c("div", [
                  _c("span", [_vm._v("풍향")]),
                  _c("span", [_vm._v(_vm._s(_vm.weather.today.windDeg))])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "Weather_Bottom" },
              _vm._l(_vm.weather.dateWeek, function(row, index) {
                return _c("div", { key: index, staticClass: "Weather_Day" }, [
                  _c("span", [_vm._v(_vm._s(row.dateWeekDay))]),
                  _c("span", [_vm._v(_vm._s(row.temperatureMax))]),
                  _c("span", [_vm._v(_vm._s(row.temperatureMin))])
                ])
              }),
              0
            )
          ]),
          _vm._m(13),
          _c(
            "div",
            { staticClass: "Notice" },
            _vm._l(_vm.noticeInfo, function(notice, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      return _vm.showNoticeDetail(index)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "Nt" }, [_vm._v("공지")]),
                  _vm._v(" " + _vm._s(notice.noticeTitle) + " ")
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "Le_Menu_Ti Le_Menu_Ti2" }, [
            _c("p", [_vm._v("금일 주의작업")]),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.todayWarnWorkOccur.length))]),
              _vm._v(" 건 ")
            ])
          ]),
          _vm.todayWarnWorkOccur.length == 0
            ? _c("div", { staticClass: "Le_Menu_Today" }, [
                _c("p", [_vm._v("금일 발생한 주의 작업이 없습니다.")])
              ])
            : _c(
                "div",
                { staticClass: "Le_Menu_Today" },
                _vm._l(_vm.todayWarnWorkOccur.data, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "Video_Events Video_Events_Warn_Work"
                    },
                    [
                      _c("p", [_vm._v(_vm._s(item.objectNm) + " 발생")]),
                      _c("div", [
                        _c("div", [
                          _c("div", [
                            _c("span", [_vm._v("일시")]),
                            _c("span", [_vm._v(_vm._s(item.warnWorkOccurDtm))])
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("현장명")]),
                            _c("span", [_vm._v(_vm._s(item.siteNm))])
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("CCTV")]),
                            _c("span", [_vm._v(_vm._s(item.cctvNm))])
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
        ]),
        _c("div", { staticClass: "Ri_Cons" }, [
          _c("div", { staticClass: "Ri_Cons_NoticeCnt" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedRedirectionOccur($event, "danger")
                  }
                }
              },
              [
                _c("p", [_vm._v("위험발생")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.dangerCount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedRedirectionOccur($event, "warnWork")
                  }
                }
              },
              [
                _c("p", [_vm._v("주의발생")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.warnWorkCount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedCnt("req")
                  }
                }
              },
              [
                _c("p", [_vm._v("조치대기")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.requestCount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedCnt("review")
                  }
                }
              },
              [
                _c("p", [_vm._v("검토대기")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.reviewCount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedCnt("actn")
                  }
                }
              },
              [
                _c("p", [_vm._v("완료")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.completeCount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.clickedCnt("today")
                  }
                }
              },
              [
                _c("p", [_vm._v("금일마감")]),
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentSituationCount.todayClosingcount))
                  ]),
                  _vm._v(" 건 ")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "Ri_Cons_Bottom" }, [
            _c("div", { staticClass: "Ri_Cons_Bottom_Left" }, [
              _c("div", { staticClass: "Ri_Cons_Bottom_Left_Ti" }, [
                _c("p", { staticClass: "Ti" }, [_vm._v("작업 현황")]),
                _c("div", { staticClass: "Status" }, [
                  _c("p", [_vm._v("22/11/11 기준")]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        class: _vm.jobSituationChart.classList[0],
                        on: {
                          click: function($event) {
                            return _vm.loadDayChart()
                          }
                        }
                      },
                      [_vm._v("일")]
                    ),
                    _c(
                      "button",
                      {
                        class: _vm.jobSituationChart.classList[1],
                        on: {
                          click: function($event) {
                            return _vm.loadWeekChart()
                          }
                        }
                      },
                      [_vm._v("주")]
                    ),
                    _c(
                      "button",
                      {
                        class: _vm.jobSituationChart.classList[2],
                        on: {
                          click: function($event) {
                            return _vm.loadMonthChart()
                          }
                        }
                      },
                      [_vm._v("월")]
                    )
                  ])
                ])
              ]),
              _vm._m(14)
            ]),
            _c(
              "div",
              { staticClass: "Ri_Cons_Bottom_Left Ri_Cons_Bottom_Report" },
              [
                _vm._m(15),
                _c("div", { staticClass: "Ri_Cons_Bottom_Left_Report" }, [
                  _c("table", [
                    _vm._m(16),
                    _vm._m(17),
                    _c(
                      "tbody",
                      _vm._l(_vm.workReporting, function(item, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.showDangerReportDetail(item, index)
                              }
                            }
                          },
                          [
                            _c("td", [
                              _vm._v(" [" + _vm._s(_vm.user.site.name) + "] "),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(item.cctvInstallPlaceCont) + " "
                              )
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.dangerOccurDtm) + " ")
                            ]),
                            _c("td", [_vm._v(_vm._s(item.objectNm))]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.targetNm) + " ")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(item.videoDangerActnDvsnNm))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "Ri_Cons_Bottom_CCTV" }, [
              _c("p", { staticClass: "Ti" }, [_vm._v("최근 위험 발생")]),
              _vm.recentDangerOccur.isLoad
                ? _c("div", [
                    _vm.recentDangerOccur.isNotEvent ||
                    !_vm.workReporting.length
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              display: "flex",
                              "justify-content": "space-around",
                              height: "459px",
                              "align-items": "center"
                            }
                          },
                          [_vm._m(18), _vm._m(19)]
                        )
                      : _vm._e(),
                    !_vm.recentDangerOccur.isNotEvent &&
                    _vm.workReporting.length
                      ? _c(
                          "div",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.curEventFrames()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "Ri_Cons_Bottom_CCTV_Le" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.recentDangerOccur.img,
                                    alt: "cctv"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "Ri_Cons_Bottom_CCTV_Ri" },
                              [
                                _c("img", {
                                  attrs: { src: _vm.recentDangerOccur.img }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "Ri_Cons_Bottom_CCTV_Txt" },
                                  [
                                    _c("div", [
                                      _c("p", [_vm._v("위험 발생")]),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.recentDangerOccur
                                              .cctvInstallPlaceCont
                                          ) + " 위험 발생"
                                        )
                                      ])
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("일시")]),
                                      _c("span", { staticClass: "date" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.recentDangerOccur.dangerOccurDtm
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("현장명")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.recentDangerOccur.siteNm)
                                        )
                                      ])
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("CCTV")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.recentDangerOccur.cctvNm)
                                        )
                                      ])
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("조치내용")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.recentDangerOccur.objectNm
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.recentDangerOccur.targetNm
                                            )
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _c("div", [
                    _c("div", {
                      staticStyle: { width: "100%", height: "459px" }
                    })
                  ])
            ])
          ]),
          _c("div", { staticClass: "Ri_Cons_Prg" }, [
            _c("div", { staticClass: "Ri_Cons_Prg_Ti" }, [
              _c("p", [_vm._v("발생 위험유형")]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.dangerOccurType.period.start) +
                    " ~ " +
                    _vm._s(_vm.dangerOccurType.period.end)
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "Ri_Cons_Prgs" },
              _vm._l(_vm.dangerOccurType.objectTargetDangerOccur, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  { key: index, staticClass: "Prgs", class: item.classList },
                  [
                    _c("div", { staticClass: "Prg_Txt" }, [
                      _c("p", [_vm._v(_vm._s(item.objectNm))]),
                      _c("p", [_vm._v(_vm._s(item.targetNm))]),
                      _c("p", [
                        _c("span", [_vm._v(_vm._s(item.percent) + "/")]),
                        _vm._v(" " + _vm._s(item.objectTargetDangerCnt) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "PrgressBar" }, [
                      _c("span", { style: item.barStyle })
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ])
      ]),
      _c("div", { ref: "changeSiteModal", staticClass: "modal" }, [
        _vm.user.selectOption.length > 0
          ? _c("div", { staticClass: "modal-content" }, [
              _vm._m(20),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.selectSite,
                        expression: "user.selectSite"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.user,
                          "selectSite",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                    _vm._l(_vm.user.selectOption, function(option, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: option.siteId } },
                        [_vm._v(" " + _vm._s(option.siteNm) + " ")]
                      )
                    })
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.clickedChangeSite()
                      }
                    }
                  },
                  [_vm._v("확인")]
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { ref: "popupImg", staticClass: "modal" }, [
        _c(
          "div",
          {
            staticClass: "modal-content",
            staticStyle: {
              width: "1300px",
              margin: "4% auto",
              padding: "20px",
              height: "800px"
            }
          },
          [
            _c(
              "span",
              { staticClass: "close", on: { click: _vm.popupImgClose } },
              [_vm._v("×")]
            ),
            _c("div", { staticStyle: { height: "30px" } }),
            _c(
              "div",
              {
                staticClass: "row",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        "overflow-y": "auto",
                        "flex-direction": "column",
                        height: "720px",
                        "max-height": "720px",
                        "border-right": "1px solid #ddd",
                        "padding-right": "15px"
                      },
                      attrs: { left: "" }
                    },
                    _vm._l(
                      _vm.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs,
                      function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: {
                              position: "relative",
                              overflow: "hidden"
                            },
                            attrs: { tabindex: index, photo: "" },
                            on: {
                              click: function($event) {
                                return _vm.selectPicture(index)
                              },
                              keydown: [
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.previousImage()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "left",
                                      37,
                                      $event.key,
                                      ["Left", "ArrowLeft"]
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    "button" in $event &&
                                    $event.button !== 0
                                  ) {
                                    return null
                                  }
                                  return _vm.previousImage()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.nextImage()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    "button" in $event &&
                                    $event.button !== 2
                                  ) {
                                    return null
                                  }
                                  return _vm.nextImage()
                                }
                              ]
                            }
                          },
                          [
                            _c("img", {
                              class: { activeImage: index == _vm.activeImage },
                              attrs: {
                                src:
                                  _vm.dangerOccurDetailsItem
                                    .inqDangerOccurFrameImgOutVOs[index].imgData
                              }
                            })
                          ]
                        )
                      }
                    ),
                    0
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-9", attrs: { align: "center" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "5 1 0%",
                          height: "inherit",
                          "justify-content": "center"
                        },
                        attrs: { align: "center", right: "" }
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "910px",
                            "max-height": "520px",
                            "object-fit": "contain",
                            display: "block"
                          },
                          attrs: { id: "popupImg", src: this.popupImg.imgData }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "5 1 0%",
                          "margin-left": "15px",
                          "justify-content": "center"
                        },
                        attrs: { align: "center", right: "" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "mBox col-md-3" }, [
                            _vm._m(21),
                            _c("div", { staticClass: "border-bottom" }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-left": "20px",
                                  "font-size": "14px"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dangerOccurDetailsItem.cctvNm) +
                                    " - " +
                                    _vm._s(
                                      _vm.dangerOccurDetailsItem
                                        .cctvInstallPlaceCont
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-left": "20px",
                                  "font-size": "14px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.popupImg.imgSaveDtm))]
                            )
                          ]),
                          _c("div", { staticClass: "mBox" }, [
                            _vm._m(22),
                            _c("div", { staticClass: "border-bottom" }),
                            _c(
                              "div",
                              {
                                key: _vm.forRerendering,
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center"
                                }
                              },
                              _vm._l(_vm.popupImg.objects, function(
                                objectAndTarget,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      display: "inline-block",
                                      "max-width": "50%",
                                      margin: "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "17px",
                                          "font-weight": "bold",
                                          "text-align": "center",
                                          "line-height": "26px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(objectAndTarget.objectNm) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm._l(objectAndTarget.targetNms, function(
                                      target,
                                      i
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticStyle: {
                                            "font-size": "14px",
                                            "text-align": "center",
                                            "line-height": "26px"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(target) + " ")]
                                      )
                                    })
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]),
      _c("event-details-popup", {
        attrs: {
          show: _vm.showDetailsPopup,
          detailsItem: _vm.dangerOccurDetailsItem,
          onClose: _vm.onCloseDetailsPopup
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("이력조회")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("조회/통계")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("시스템관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("대상물관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("AI관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", [_vm._v("기기관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("모니터링")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("위험발생이력")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("조회/통계")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("span", [_vm._v("AI/데이터관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/dashboardlib/images/weather/sun.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Le_Menu_Ti" }, [
      _c("p", [_vm._v("공지사항")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Ri_Cons_Bottom_Left_Status" }, [
      _c("div", { staticStyle: { height: "100%" }, attrs: { id: "lineChart" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Ri_Cons_Bottom_Left_Ti" }, [
      _c("p", { staticClass: "Ti" }, [_vm._v("작업 보고")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col"),
      _c("col"),
      _c("col"),
      _c("col"),
      _c("col")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("CCTV 구역")]),
        _c("th", [_vm._v("발생시간")]),
        _c("th", [_vm._v("대상물")]),
        _c("th", [_vm._v("조치사항")]),
        _c("th", [_vm._v("상태")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "250px" },
        attrs: { src: "/images/icon-no-result.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticStyle: { "font-size": "1.4em" } }, [
        _vm._v("현재 위험 발생한 이벤트가 없습니다")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("span", { staticClass: "modal-title" }, [_vm._v("현장 선택")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("장소 및 시각")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("조치 대상")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }