<template>
	<div class="Wrap" v-cloak>
		<div class="Header">
			<div class="Nav">
				<!-- 건설메뉴 -->
				<ul v-if="menuGubun == '02'">
					<li v-if="hasAuthority(['/viewAccidentPrevent.do'])">
						<a style="cursor: pointer" @click="loadView('accidentPrevent', $event)" data-desc="재해예방조치">
							<span>재해예방조치</span>
						</a>
					</li>
					<li v-if="hasAuthority(['/viewDangerWarnWorkOccur.do', '/aiDistinguish.do'])">
						<a>
							<span>이력조회</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewDangerWarnWorkOccur.do'])">
									<a @click="loadView('dangerWarnWorkOccur', $event)" data-desc="발생이력">
										<span>발생이력</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/aiDistinguish.do'])">
									<a @click="loadView('aiDistinguish', $event)" data-desc="AI판별이력">
										<span>AI판별이력</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li
						v-if="
							hasAuthority([
								'/viewTree.do',
								'/viewSiteStatistic.do',
								'/viewAccidentStatistic.do',
								'/viewObjectDangerOccurStatistic.do',
								'/viewObjectDangerOccurStatisticBar.do',
								'/viewObjectDangerOccurStatisticLine2.do',
								'/viewObjectDangerOccurStatisticBar2.do',
							])
						"
					>
						<a>
							<span>조회/통계</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewTree.do'])">
									<a style="cursor: pointer" @click="loadView('tree', $event)" data-desc="현장별사용자조회">
										<span>현장별 사용자 조회</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSiteStatistic.do'])">
									<a style="cursor: pointer" @click="loadView('siteStatistic', $event)" data-desc="현장별통계">
										<span>현장별통계</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewAccidentStatistic.do'])">
									<a style="cursor: pointer" @click="loadView('accidentStatistic', $event)" data-desc="재해유형별통계">
										<span>재해유형별통계</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatistic', $event)" data-desc="상황별 발생 추이">
										<span>상황별 발생 추이</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticBar', $event)" data-desc="상황별 발생 건수">
										<span>상황별 발생 건수</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticLine2.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticLine2', $event)" data-desc="상황별 발생 추이(본사)">
										<span>상황별 발생 추이(본사)</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar2.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticBar2', $event)" data-desc="상황별 발생 건수(본사)">
										<span>상황별 발생 건수(본사)</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li v-if="hasAuthority(['/viewExemplaryCase.do'])">
						<a style="cursor: pointer" @click="loadView('exemplaryCase', $event)" data-desc="모범사례">
							<span>모범사례</span>
						</a>
					</li>
					<li
						v-show="
							hasAuthority([
								'/viewCompany.do',
								'/viewSite.do',
								'/viewUser.do',
								'/viewRegisterUser.do',
								'/viewSettingPushAlarm.do',
								'/viewNotice.do',
								'/viewMenu.do',
								'/viewRoleGroup.do',
								'/viewGrpCode.do',
								'/viewCommCode.do',
								'/viewApiCallErrLog.do',
								'/viewObject.do',
								'/viewTarget.do',
								'/viewObjectTarget.do',
								'/viewWarnWork.do',
								'/viewBasicAiConfig.do',
								'/viewAiConfigDistigshSutbDgreCctv.do',
								'/viewCctvDangerAreaConfig.do',
								'/viewCctvAiConfig.do',
								'/viewSpeakerAiConfig.do',
								'/viewCctv.do',
								'/viewSpeaker.do',
								'/viewSpeakerSoundsrc.do',
								'/viewSpeakerCctv.do',
								'/viewCctvBySafeManager.do',
							])
						"
					>
						<a @mouseover="setSubMenuLocation()">
							<span>관리</span>
						</a>
						<div ref="navSubMultiple" class="Nav_Sub_Multiple">
							<div class="mng_menu" v-if="hasAuthority(['/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewRegisterUser.do'])">
								<ul>
									<li>
										<div>조직관리</div>
									</li>
									<li v-if="hasAuthority(['/viewCompany.do'])">
										<a style="cursor: pointer" @click="loadView('company', $event)" data-desc="업체관리">
											<span>업체관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSite.do'])">
										<a style="cursor: pointer" @click="loadView('site', $event)" data-desc="현장관리">
											<span>현장관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewUser.do'])">
										<a style="cursor: pointer" @click="loadView('user', $event)" data-desc="사용자관리">
											<span>사용자관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewRegisterUser.do'])">
										<a style="cursor: pointer" @click="loadView('registerUser', $event)" data-desc="사용자가입승인">
											<span>사용자가입승인</span>
										</a>
									</li>
								</ul>
							</div>
							<div
								class="mng_menu"
								v-if="
									hasAuthority([
										'/viewNotice.do',
										'/viewMenu.do',
										'/viewRoleGroup.do',
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewApiCallErrLog.do',
										'/viewSettingPushAlarm.do',
									])
								"
							>
								<ul>
									<li>
										<div>시스템관리</div>
									</li>
									<li v-if="hasAuthority(['/viewNotice.do'])">
										<a style="cursor: pointer" @click="loadView('notice', $event)" data-desc="공지사항">
											<span>공지사항</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewMenu.do'])">
										<a style="cursor: pointer" @click="loadView('menu', $event)" data-desc="메뉴코드관리">
											<span>메뉴코드관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewRoleGroup.do'])">
										<a style="cursor: pointer" @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
											<span>권한그룹관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewGrpCode.do'])">
										<a style="cursor: pointer" @click="loadView('grpCode', $event)" data-desc="공통그룹코드관리">
											<span>공통그룹코드관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewCommCode.do'])">
										<a style="cursor: pointer" @click="loadView('commCode', $event)" data-desc="공통코드관리">
											<span>공통코드관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
										<a style="cursor: pointer" @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
											<span>API호출오류로그조회</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSettingPushAlarm.do'])">
										<a style="cursor: pointer" @click="loadView('settingPushAlarm', $event)" data-desc="푸시알림관리">
											<span>푸시알림관리</span>
										</a>
									</li>
								</ul>
							</div>
							<div class="mng_menu" v-if="hasAuthority(['/viewObject.do', '/viewTarget.do', '/viewObjectTarget.do', '/viewWarnWork.do'])">
								<ul>
									<li>
										<div>대상물관리</div>
									</li>
									<li v-if="hasAuthority(['/viewObject.do'])">
										<a style="cursor: pointer" @click="loadView('object', $event)" data-desc="대상물관리">
											<span>대상물관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewTarget.do'])">
										<a style="cursor: pointer" @click="loadView('target', $event)" data-desc="조치대상관리">
											<span>조치대상관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewObjectTarget.do'])">
										<a style="cursor: pointer" @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
											<span>대상물조치대상관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewWarnWork.do'])">
										<a style="cursor: pointer" @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
											<span>위험대상물조합관리</span>
										</a>
									</li>
								</ul>
							</div>
							<div
								class="mng_menu"
								v-if="
									hasAuthority([
										'/viewBasicAiConfig.do',
										'/viewAiConfigDistigshSutbDgreCctv.do',
										'/viewCctvDangerAreaConfig.do',
										'/viewSiteTrespassConfig.do',
										'/viewCctvAiConfig.do',
										'/viewSpeakerAiConfig.do',
									])
								"
							>
								<ul>
									<li>
										<div>AI관리</div>
									</li>
									<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
										<a style="cursor: pointer" @click="loadView('cctvAiConfig', $event)" data-desc="CCTV 대상물 설정">
											<span>CCTV 대상물 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
										<a style="cursor: pointer" @click="loadView('basicAiConfig', $event)" data-desc="위험 판단 기준 설정">
											<span>위험 판단 기준 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewAiConfigDistigshSutbDgreCctv.do'])">
										<a style="cursor: pointer" @click="loadView('aiConfigDistigshSutbDgreCctv', $event)" data-desc="AI-threshold 설정">
											<span>AI-threshold 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
										<a style="cursor: pointer" @click="loadView('cctvDangerAreaConfig', $event)" data-desc="위험지역 설정">
											<span>위험지역 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSiteTrespassConfig.do'])">
										<a style="cursor: pointer" @click="loadView('siteTrespassConfig', $event)" data-desc="침입탐지 설정">
											<span>침입탐지 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSpeakerAiConfig.do'])">
										<a style="cursor: pointer" @click="loadView('speakerAiConfig', $event)" data-desc="스피커대상물 설정">
											<span>스피커대상물 설정</span>
										</a>
									</li>
								</ul>
							</div>
							<div
								class="mng_menu"
								v-if="
									hasAuthority(['/viewCctv.do', '/viewSpeaker.do', '/viewSpeakerSoundsrc.do', '/viewSpeakerCctv.do', '/viewCctvBySafeManager.do'])
								"
							>
								<ul>
									<li>
										<div>기기관리</div>
									</li>
									<li v-if="hasAuthority(['/viewCctv.do'])">
										<a style="cursor: pointer" @click="loadView('cctv', $event)" data-desc="CCTV관리">
											<span>CCTV관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewCctvBySafeManager.do'])">
										<a style="cursor: pointer" @click="loadView('cctvBySafeManager', $event)" data-desc="CCTV 설치장소 변경">
											<span>CCTV 설치장소 변경</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSpeaker.do'])">
										<a style="cursor: pointer" @click="loadView('speaker', $event)" data-desc="스피커관리">
											<span>스피커관리</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSpeakerCctv.do'])">
										<a style="cursor: pointer" @click="loadView('speakerCctv', $event)" data-desc="스피커-CCTV 관계 설정">
											<span>스피커-CCTV 관계 설정</span>
										</a>
									</li>
									<li v-if="hasAuthority(['/viewSpeakerSoundsrc.do'])">
										<a style="cursor: pointer" @click="loadView('speakerSoundsrc', $event)" data-desc="스피커음원관리">
											<span>스피커음원관리</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<li>
						<a>
							<span>모니터링</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li>
									<a style="cursor: pointer" @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
										<span>현장 모니터링</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<!-- 현대제철용 메뉴 -->
				<ul v-if="menuGubun == '01'">
					<li
						v-if="
							hasAuthority([
								'/viewDangerOccur.do',
								'/viewWarnWorkOccur.do',
								'/viewObjectDangerOccurStatistic.do',
								'/viewObjectDangerOccurStatisticBar.do',
								'/viewObjectDangerOccurStatisticLine2.do',
								'/viewObjectDangerOccurStatisticBar2.do',
							])
						"
					>
						<a>
							<span>위험발생이력</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewDangerOccur.do'])">
									<a style="cursor: pointer" @click="loadView('dangerOccur', $event)" data-desc="위험발생이력">
										<span>위험발생이력</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewDangerOccur.do'])">
									<a style="cursor: pointer" @click="loadView('dangerOccurVideo', $event)" data-desc="위험발생이력">
										<span>위험발생이력-비디오</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
									<a style="cursor: pointer" @click="loadView('warnWorkOccur', $event)" data-desc="주의작업이력">
										<span>주의작업이력</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
									<a style="cursor: pointer" @click="loadView('warnWorkOccurVideo', $event)" data-desc="주의작업이력">
										<span>주의작업이력-비디오</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li v-if="hasAuthority(['/viewTree.do', '/viewObjectDangerOccurStatistic.do'])">
						<a>
							<span>조회/통계</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewTree.do'])">
									<a style="cursor: pointer" @click="loadView('tree', $event)" data-desc="현장별사용자조회">
										<span>현장별 사용자 조회</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatistic', $event)" data-desc="상황별 발생 추이">
										<span>상황별 발생 추이</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticBar', $event)" data-desc="상황별 발생 건수">
										<span>상황별 발생 건수</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticLine2.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticLine2', $event)" data-desc="상황별 발생 추이(본사)">
										<span>상황별 발생 추이(본사)</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectDangerOccurStatisticBar2.do'])">
									<a style="cursor: pointer" @click="loadView('objectDangerOccurStatisticBar2', $event)" data-desc="상황별 발생 건수(본사)">
										<span>상황별 발생 건수(본사)</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li v-if="hasAuthority(['/viewNotice.do', '/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewMenu.do', '/viewRoleGroup.do'])">
						<a>
							<span>조직관리</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewNotice.do'])">
									<a style="cursor: pointer" @click="loadView('notice', $event)" data-desc="공지사항">
										<span>공지사항</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCompany.do'])">
									<a style="cursor: pointer" @click="loadView('company', $event)" data-desc="업체관리">
										<span>업체관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSite.do'])">
									<a style="cursor: pointer" @click="loadView('site', $event)" data-desc="현장관리">
										<span>현장관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewUser.do'])">
									<a style="cursor: pointer" @click="loadView('user', $event)" data-desc="사용자관리">
										<span>사용자관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewRegisterUser.do'])">
									<a style="cursor: pointer" @click="loadView('registerUser', $event)" data-desc="사용자가입승인">
										<span>사용자가입승인</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewMenu.do'])">
									<a style="cursor: pointer" @click="loadView('menu', $event)" data-desc="메뉴코드관리">
										<span>메뉴코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewRoleGroup.do'])">
									<a style="cursor: pointer" @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
										<span>권한그룹관리</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li
						v-if="
							hasAuthority([
								'/viewGrpCode.do',
								'/viewCommCode.do',
								'/viewObject.do',
								'/viewTarget.do',
								'/viewObjectTarget.do',
								'/viewWarnWork.do',
								'/viewCctv.do',
								'/viewBasicAiConfig.do',
								'/viewCctvAiConfig.do',
								'/viewApiCallErrLog.do',
								'/viewCctvDangerAreaConfig.do',
							])
						"
					>
						<a>
							<span>AI/데이터관리</span>
						</a>
						<div class="Nav_Sub">
							<ul>
								<li v-if="hasAuthority(['/viewGrpCode.do'])">
									<a style="cursor: pointer" @click="loadView('grpCode', $event)" data-desc="공통그룹코드관리">
										<span>공통그룹코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCommCode.do'])">
									<a style="cursor: pointer" @click="loadView('commCode', $event)" data-desc="공통코드관리">
										<span>공통코드관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObject.do'])">
									<a style="cursor: pointer" @click="loadView('object', $event)" data-desc="대상물관리">
										<span>대상물관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewTarget.do'])">
									<a style="cursor: pointer" @click="loadView('target', $event)" data-desc="조치대상관리">
										<span>조치대상관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewObjectTarget.do'])">
									<a style="cursor: pointer" @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
										<span>대상물조치대상관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewWarnWork.do'])">
									<a style="cursor: pointer" @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
										<span>위험대상물조합관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctv.do'])">
									<a style="cursor: pointer" @click="loadView('cctv', $event)" data-desc="CCTV관리">
										<span>CCTV관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
									<a style="cursor: pointer" @click="loadView('cctvAiConfig', $event)" data-desc="CCTV 대상물 설정">
										<span>CCTV 대상물 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
									<a style="cursor: pointer" @click="loadView('basicAiConfig', $event)" data-desc="위험 판단 기준 설정">
										<span>위험 판단 기준 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
									<a style="cursor: pointer" @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
										<span>API호출오류로그조회</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
									<a style="cursor: pointer" @click="loadView('cctvDangerAreaConfig', $event)" data-desc="위험지역 설정">
										<span>위험지역 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSiteTrespassConfig.do'])">
									<a style="cursor: pointer" @click="loadView('siteTrespassConfig', $event)" data-desc="침입탐지 설정">
										<span>침입탐지 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeaker.do'])">
									<a style="cursor: pointer" @click="loadView('speaker', $event)" data-desc="스피커관리">
										<span>스피커관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerSoundsrc.do'])">
									<a style="cursor: pointer" @click="loadView('speakerSoundsrc', $event)" data-desc="스피커음원관리">
										<span>스피커음원관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerCctv.do'])">
									<a style="cursor: pointer" @click="loadView('speakerCctv', $event)" data-desc="스피커-CCTV 관계 설정">
										<span>스피커-CCTV 관계 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewSpeakerAiConfig.do'])">
									<a style="cursor: pointer" @click="loadView('speakerAiConfig', $event)" data-desc="스피커대상물 설정">
										<span>스피커대상물 설정</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewCctvBySafeManager.do'])">
									<a style="cursor: pointer" @click="loadView('cctvBySafeManager', $event)" data-desc="CCTV 설치장소 관리">
										<span>CCTV 설치장소 관리</span>
									</a>
								</li>
								<li v-if="hasAuthority(['/viewAiConfigDistigshSutbDgreCctv.do'])">
									<a style="cursor: pointer" @click="loadView('aiConfigDistigshSutbDgreCctv', $event)" data-desc="AI-threshold 설정">
										<span>AI-threshold 설정</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<a style="cursor: pointer" @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
							<span>모니터링</span>
						</a>
					</li>
				</ul>
			</div>
			<div class="Login">
				<a href="/download/사용자매뉴얼_본사관리자용.pdf" class="Guide_Download" download="" v-if="loginUserInfo.userRoleCd == '00'">
					매뉴얼 다운로드
				</a>
				<a href="/download/사용자매뉴얼_안전관리자용.pdf" class="Guide_Download" download="" v-if="loginUserInfo.userRoleCd == '01'">
					매뉴얼 다운로드
				</a>
				<button>
					<img src="images/new-main/Profile.png" />
					<span>{{ user.id }}</span>
					<div class="Login_Sub" :style="`${loginUserInfo.userRoleCd === '04' ? 'right: 188px' : ''}`">
						<div style="cursor: pointer" @click="logout()">로그아웃</div>
						<div style="cursor: pointer" @click="changeSiteId()">현장변경</div>
					</div>
				</button>
			</div>
		</div>
		<div class="Container">
			<div class="Le_Menu">
				<div class="Le_Menu_Ti">
					<p>{{ user.site.name }}</p>
					<p>{{ nowDate }}</p>
				</div>
				<div class="Weather">
					<div class="Weather_Top">
						<div class="Weather_Left">
							<div><img src="/dashboardlib/images/weather/sun.png" /></div>
							<div style="padding-left: 12px">
								<span v-if="weather.today.temperature >= '5'" class="Red">{{ weather.today.temperature }}˚</span>
								<span v-if="weather.today.temperature < '5'" class="Blue">{{ weather.today.temperature }}˚</span>
							</div>
						</div>
						<div class="Weather_Right">
							<div>
								<span>습도</span>
								<span>{{ weather.today.humidity }}</span>
							</div>
							<div>
								<span>풍속</span>
								<span>{{ weather.today.windSpeed }}m</span>
							</div>
							<div>
								<span>풍향</span>
								<span>{{ weather.today.windDeg }}</span>
							</div>
						</div>
					</div>
					<div class="Weather_Bottom">
						<div class="Weather_Day" v-for="(row, index) in weather.dateWeek" :key="index">
							<span>{{ row.dateWeekDay }}</span>
							<span>{{ row.temperatureMax }}</span>
							<span>{{ row.temperatureMin }}</span>
						</div>
					</div>
				</div>
				<div class="Le_Menu_Ti">
					<p>공지사항</p>
				</div>
				<div class="Notice">
					<div v-for="(notice, index) in noticeInfo" :key="index" @click="showNoticeDetail(index)" style="cursor: pointer">
						<span class="Nt">공지</span>
						{{ notice.noticeTitle }}
					</div>
				</div>
				<div class="Le_Menu_Ti Le_Menu_Ti2">
					<p>금일 주의작업</p>
					<p>
						<span>{{ todayWarnWorkOccur.length }}</span>
						건
					</p>
				</div>
				<div v-if="todayWarnWorkOccur.length == 0" class="Le_Menu_Today">
					<p>금일 발생한 주의 작업이 없습니다.</p>
				</div>
				<div v-else class="Le_Menu_Today">
					<div v-for="(item, index) in todayWarnWorkOccur.data" :key="index" class="Video_Events Video_Events_Warn_Work">
						<p>{{ item.objectNm }} 발생</p>
						<div>
							<div>
								<div>
									<span>일시</span>
									<span>{{ item.warnWorkOccurDtm }}</span>
								</div>
								<div>
									<span>현장명</span>
									<span>{{ item.siteNm }}</span>
								</div>
								<div>
									<span>CCTV</span>
									<span>{{ item.cctvNm }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Ri_Cons">
				<div class="Ri_Cons_NoticeCnt">
					<div style="cursor: pointer" @click="clickedRedirectionOccur($event, 'danger')">
						<p>위험발생</p>
						<p>
							<span>{{ currentSituationCount.dangerCount }}</span>
							건
						</p>
					</div>
					<div style="cursor: pointer" @click="clickedRedirectionOccur($event, 'warnWork')">
						<p>주의발생</p>
						<p>
							<span>{{ currentSituationCount.warnWorkCount }}</span>
							건
						</p>
					</div>
					<!-- <div style="cursor: pointer" @click="clickedRedirectionDangerOccurTakingAction">
						<p>조치 중</p>
						<p>
							<span>{{ currentSituationCount.actionCount }}</span>
							건
						</p>
					</div> -->
					<div style="cursor: pointer" @click="clickedCnt('req')">
						<p>조치대기</p>
						<p>
							<span>{{ currentSituationCount.requestCount }}</span>
							건
						</p>
					</div>
					<div style="cursor: pointer" @click="clickedCnt('review')">
						<p>검토대기</p>
						<p>
							<span>{{ currentSituationCount.reviewCount }}</span>
							건
						</p>
					</div>
					<div style="cursor: pointer" @click="clickedCnt('actn')">
						<p>완료</p>
						<p>
							<span>{{ currentSituationCount.completeCount }}</span>
							건
						</p>
					</div>
					<div style="cursor: pointer" @click="clickedCnt('today')">
						<p>금일마감</p>
						<p>
							<span>{{ currentSituationCount.todayClosingcount }}</span>
							건
						</p>
					</div>
				</div>
				<div class="Ri_Cons_Bottom">
					<div class="Ri_Cons_Bottom_Left">
						<div class="Ri_Cons_Bottom_Left_Ti">
							<p class="Ti">작업 현황</p>
							<div class="Status">
								<p>22/11/11 기준</p>
								<div>
									<button :class="jobSituationChart.classList[0]" @click="loadDayChart()">일</button>
									<button :class="jobSituationChart.classList[1]" @click="loadWeekChart()">주</button>
									<button :class="jobSituationChart.classList[2]" @click="loadMonthChart()">월</button>
								</div>
							</div>
						</div>
						<div class="Ri_Cons_Bottom_Left_Status">
							<div id="lineChart" style="height: 100%"></div>
						</div>
					</div>
					<div class="Ri_Cons_Bottom_Left Ri_Cons_Bottom_Report">
						<div class="Ri_Cons_Bottom_Left_Ti">
							<p class="Ti">작업 보고</p>
						</div>
						<div class="Ri_Cons_Bottom_Left_Report">
							<table>
								<colgroup>
									<col />
									<col />
									<col />
									<col />
									<col />
								</colgroup>
								<thead>
									<tr>
										<th>CCTV 구역</th>
										<th>발생시간</th>
										<th>대상물</th>
										<th>조치사항</th>
										<th>상태</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in workReporting" :key="index" style="cursor: pointer" @click="showDangerReportDetail(item, index)">
										<td>
											[{{ user.site.name }}]
											<br />
											{{ item.cctvInstallPlaceCont }}
										</td>
										<td>
											{{ item.dangerOccurDtm }}
										</td>
										<td>{{ item.objectNm }}</td>
										<td>
											{{ item.targetNm }}
										</td>
										<td>{{ item.videoDangerActnDvsnNm }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="Ri_Cons_Bottom_CCTV">
						<p class="Ti">최근 위험 발생</p>
						<div v-if="recentDangerOccur.isLoad">
							<div
								v-if="recentDangerOccur.isNotEvent || !workReporting.length"
								style="width: 100%; display: flex; justify-content: space-around; height: 459px; align-items: center"
							>
								<div>
									<img style="width: 250px" src="/images/icon-no-result.png" />
								</div>
								<div>
									<span style="font-size: 1.4em">현재 위험 발생한 이벤트가 없습니다</span>
								</div>
							</div>
							<div v-if="!recentDangerOccur.isNotEvent && workReporting.length" style="cursor: pointer" @click="curEventFrames()">
								<div class="Ri_Cons_Bottom_CCTV_Le">
									<img :src="recentDangerOccur.img" alt="cctv" />
								</div>
								<div class="Ri_Cons_Bottom_CCTV_Ri">
									<img :src="recentDangerOccur.img" />
									<div class="Ri_Cons_Bottom_CCTV_Txt">
										<div>
											<p>위험 발생</p>
											<p>{{ recentDangerOccur.cctvInstallPlaceCont }} 위험 발생</p>
										</div>
										<div>
											<span>일시</span>
											<span class="date">{{ recentDangerOccur.dangerOccurDtm }}</span>
										</div>
										<div>
											<span>현장명</span>
											<span>{{ recentDangerOccur.siteNm }}</span>
										</div>
										<div>
											<span>CCTV</span>
											<span>{{ recentDangerOccur.cctvNm }}</span>
										</div>
										<div>
											<span>조치내용</span>
											<span>{{ recentDangerOccur.objectNm }} {{ recentDangerOccur.targetNm }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div style="width: 100%; height: 459px"></div>
						</div>
					</div>
				</div>
				<div class="Ri_Cons_Prg">
					<div class="Ri_Cons_Prg_Ti">
						<p>발생 위험유형</p>
						<p>{{ dangerOccurType.period.start }} ~ {{ dangerOccurType.period.end }}</p>
					</div>
					<div class="Ri_Cons_Prgs">
						<div v-for="(item, index) in dangerOccurType.objectTargetDangerOccur" :key="index" class="Prgs" :class="item.classList">
							<div class="Prg_Txt">
								<p>{{ item.objectNm }}</p>
								<p>{{ item.targetNm }}</p>
								<p>
									<span>{{ item.percent }}/</span>
									{{ item.objectTargetDangerCnt }}
								</p>
							</div>
							<div class="PrgressBar">
								<span :style="item.barStyle"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div ref="changeSiteModal" class="modal">
			<div class="modal-content" v-if="user.selectOption.length > 0">
				<div class="modal-header">
					<span class="modal-title">현장 선택</span>
				</div>
				<div class="modal-body">
					<select class="form-control" v-model="user.selectSite">
						<option value>선택</option>
						<option v-for="(option, index) in user.selectOption" :value="option.siteId" :key="index">
							{{ option.siteNm }}
						</option>
					</select>
				</div>
				<div class="modal-footer">
					<button class="btn btn-primary" @click="clickedChangeSite()">확인</button>
				</div>
			</div>
		</div>
		<div ref="popupImg" class="modal">
			<!-- Modal content -->
			<div class="modal-content" style="width: 1300px; margin: 4% auto; padding: 20px; height: 800px">
				<span @click="popupImgClose" class="close">&times;</span>
				<div style="height: 30px"></div>
				<div class="row" style="display: flex; align-items: center; justify-content: center">
					<div class="col-md-3">
						<div
							style="
								flex: 1;
								overflow-y: auto;
								flex-direction: column;
								height: 720px;
								max-height: 720px;
								border-right: 1px solid #ddd;
								padding-right: 15px;
							"
							left
						>
							<div
								style="position: relative; overflow: hidden"
								v-for="(item, index) in dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs"
								:key="index"
								@click="selectPicture(index)"
								:tabindex="index"
								@keydown.up="previousImage()"
								@keydown.left="previousImage()"
								@keydown.down="nextImage()"
								@keydown.right="nextImage()"
								photo
							>
								<img :src="dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[index].imgData" :class="{ activeImage: index == activeImage }" />

								<!-- <div>
									<div style="position: absolute; width: 100%; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%)"></div>
								</div> -->
							</div>
						</div>
					</div>
					<div align="center" class="col-md-9">
						<div align="center" right style="flex: 5 1 0%; height: inherit; justify-content: center">
							<img id="popupImg" :src="this.popupImg.imgData" style="width: 910px; max-height: 520px; object-fit: contain; display: block" />
						</div>
						<div align="center" right style="flex: 5 1 0%; margin-left: 15px; justify-content: center">
							<div class="row">
								<div class="mBox col-md-3">
									<div><h4>장소 및 시각</h4></div>
									<div class="border-bottom"></div>
									<div style="margin-top: 10px; margin-left: 20px; font-size: 14px">
										{{ dangerOccurDetailsItem.cctvNm }} - {{ dangerOccurDetailsItem.cctvInstallPlaceCont }}
									</div>
									<div style="margin-top: 10px; margin-left: 20px; font-size: 14px">{{ popupImg.imgSaveDtm }}</div>
								</div>
								<div class="mBox">
									<div><h4>조치 대상</h4></div>
									<div class="border-bottom"></div>
									<div style="display: flex; justify-content: center" :key="forRerendering">
										<div
											v-for="(objectAndTarget, index) in popupImg.objects"
											:key="index"
											style="display: inline-block; max-width: 50%; margin: 10px"
										>
											<div style="font-size: 17px; font-weight: bold; text-align: center; line-height: 26px">
												{{ objectAndTarget.objectNm }}
											</div>
											<div style="font-size: 14px; text-align: center; line-height: 26px" v-for="(target, i) in objectAndTarget.targetNms" :key="i">
												{{ target }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<event-details-popup :show="showDetailsPopup" :detailsItem="dangerOccurDetailsItem" :onClose="onCloseDetailsPopup" />
	</div>
</template>

<script>
import VueCookies from 'vue-cookies';
import jwt from 'jsonwebtoken';
import apiIndex from '../../api/index';
import moment from 'moment';
import EventDetailsPopup from '@/components/EventDetailsPopup';

const mainUrl = apiIndex.main;
const dangerOccurUrl = apiIndex.dangerOccur;
const accidentPreUrl = apiIndex.accidentPrevent;
let axiosExtention;

export default {
	components: {
		EventDetailsPopup,
	},
	data: () => ({
		nowDate: '',
		tick: 1,
		menuGubun: null,
		// 사용자 정보
		user: {
			id: 'unknown',
			name: null,
			site: {
				id: null,
				name: null,
				latitude: null,
				longitude: null,
			},
			accessibleMenus: [],
			selectOption: [],
			selectSite: '',
		},
		loginUserInfo: {},
		weather: {
			weekDayStrArr: ['일', '월', '화', '수', '목', '금', '토'],
			dateWeek: [],
			today: {
				icon: '/dashboardlib/images/weather/sun.png',
				temperature: '',
				humidity: '',
				windSpeed: '',
				windDeg: '',
			},
		},
		commonCodeList: [],
		// 최근 위험 발생
		recentDangerOccur: {
			isLoad: false,
			isNotEvent: true,
			siteNm: null,
			cctvInstallPlaceCont: null,
		},
		currentSituationCount: {
			dangerCount: 0, // 위험발생
			warnWorkCount: 0, // 주의발생
			actionCount: 0, // 조치 중
			requestCount: 0, // 조치대기
			reviewCount: 0, // 검토 대기
			completeCount: 0, // 완료
			todayClosingcount: 0, // 금일 마감
		},
		// 발생 위험유형
		dangerOccurType: {
			period: {
				start: null,
				end: null,
			},
			objectTargetDangerOccur: [],
			objectTargetDangerOccurTotal: 0,
		},
		workReporting: [], // 작업보고
		noticeInfo: {}, // 공지사항
		// 금일 주의작업
		todayWarnWorkOccur: {
			length: 0,
			data: [],
		},
		// 작업 현황 차트
		jobSituationChart: {
			classList: ['On', '', ''],
			dataSource: {},
			categoryAxis: {
				// 데이터를 가져올 필드명 세팅
				field: 'reqDtm',
				// rotation: 화면이 작아졌을 때 세로로 보여줄 거냐..
				// foramt: 값을 보여줄 형태 지정
				// step: 값이 몇 씩 증가하는가..
				labels: { rotation: 'auto', format: 'M/d', step: 1, font: '10px Arial, Helvetica, sans-serif', color: '#919193' },
				majorGridLines: {
					color: '#949494',
				},
			},
			valueAxis: {
				// format: y축 데이터를 어떻게 표시할 것인가..
				labels: { format: '{0:n0}건', font: '11px Arial, Helvetica, sans-serif', color: '#919193' },
				majorGridLines: {
					color: '#949494',
				},
			},
			series: [],
			legend: {
				position: 'bottom',
				labels: {
					color: '#FFFFFF',
				},
			},
			chartArea: {
				background: '#2b2c30',
			},
			tooltip: {
				// 툴팁 표시, 비표시 설정
				visible: true,
				// 툴팁 내용을 어떻게 표시할 것인가..
				template: `#: category #<br/>#: series.name # : #: kendo.toString(value, 'n0') #건`,
			},
		},
		dangerOccurDetailsItem: {},
		popupImg: {},
		activeImage: 0,
		forRerendering: 0,
		scrollDirection: '',
		showDetailsPopup: false,
	}),
	async created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.nowDate = this.date();

		let accessToken = VueCookies.get('V2_ACCESS_TOKEN');
		let decodedAccessToken = jwt.decode(accessToken);
		this.user.id = decodedAccessToken.userId;
		this.user.site.id = decodedAccessToken.loginUserSiteId;
		this.user.accessibleMenus = JSON.parse(decodedAccessToken.userMenu);

		sessionStorage.setItem('loginUserInfo', JSON.stringify(decodedAccessToken));

		this.dangerOccurType.period.start = moment().add(-6, 'day').format('YYYY-MM-DD');
		this.dangerOccurType.period.end = moment().format('YYYY-MM-DD');

		this.commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
		if (!this.commonCodeList || this.commonCodeList.length == 0) {
			await this.$axios
				.post(apiIndex.commCode.inqCommCodeAllList, {})
				.then(
					function (r) {
						this.commonCodeList = r.data;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		}

		if (navigator.geolocation) {
			this.handleGeoSucces();
		} else {
			this.handleGeoError();
		}

		this.getUserInfo();
		this.getOccurEventCount();
		this.getCurrentSituationCount();
		this.getWorkReporting();
		this.getDangerOccurType();
		this.getRecentDangerOccur();
		this.getRecentlyNotice();
		this.getTodayWarnWorkOccur();
		this.loadDayChart();

		setInterval(() => {
			this.nowDate = this.date();
			if (this.tick++ % 60 != 0) return;
			this.reloadData();
			this.tick = 1;
		}, 1000);
	},
	mounted() {
		this.loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
	},
	computed: {},
	watch: {
		activeImage(value, oldValue) {
			if (value > oldValue) {
				this.scrollDirection = 'down';
			} else if (value < oldValue) {
				this.scrollDirection = 'up';
			}
			this.popupImg = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activeImage];
		},
	},
	methods: {
		setSubMenuLocation() {
			let mngMenuWrapper = this.$refs.navSubMultiple;
			let basicWidth = mngMenuWrapper.getBoundingClientRect().width;
			let halfMenuLength = Math.trunc(document.getElementsByClassName('mng_menu').length / 2);
			mngMenuWrapper.style.marginLeft = -(basicWidth * halfMenuLength) + 'px';
		},
		hasAuthority(urlList) {
			return urlList.some(r => this.user.accessibleMenus.includes(r));
		},
		async loadView(name) {
			let url = '';
			if (name == 'accidentPrevent') url = apiIndex.accidentPrevent.viewAccidentPrevent;
			else if (name == 'warnWorkOccur') url = apiIndex.warnWorkOccur.viewWarnWorkOccur;
			else if (name == 'warnWorkOccurVideo') url = apiIndex.warnWorkOccur.viewWarnWorkOccurVideo;
			else if (name == 'dangerOccur') url = apiIndex.dangerOccur.viewDangerOccur;
			else if (name == 'dangerOccurVideo') url = apiIndex.dangerOccur.viewDangerOccurVideo;
			else if (name == 'dangerWarnWorkOccur') url = apiIndex.dangerWarnWorkOccur.viewDangerWarnWorkOccur;
			else if (name == 'aiDistinguish') url = apiIndex.aiDistinguish.viewAiDistinguish;
			else if (name == 'siteStatistic') url = apiIndex.siteStatistic.viewSiteStatistic;
			else if (name == 'accidentStatistic') url = apiIndex.accidentStatistic.viewAccidentStatistic;
			else if (name == 'site') url = apiIndex.site.viewSite;
			else if (name == 'user') url = apiIndex.user.viewUser;
			else if (name == 'menu') url = apiIndex.menu.viewMenu;
			else if (name == 'roleGroup') url = apiIndex.roleGroup.viewRoleGroup;
			else if (name == 'commCode') url = apiIndex.commCode.viewCommCode;
			else if (name == 'object') url = apiIndex.object.viewObject;
			else if (name == 'objectTarget') url = apiIndex.objectTarget.viewObjectTarget;
			else if (name == 'warnWork') url = apiIndex.warnWork.viewWarnWork;
			else if (name == 'cctv') url = apiIndex.cctv.viewCctv;
			else if (name == 'basicAiConfig') url = apiIndex.basicAiConfig.viewBasicAiConfig;
			else if (name == 'cctvAiConfig') url = apiIndex.cctvAiConfig.viewCctvAiConfig;
			else if (name == 'apiCallErrLog') url = apiIndex.apiCallErrLog.viewApiCallErrLog;
			else if (name == 'cctvDangerAreaConfig') url = apiIndex.cctvDangerAreaConfig.viewCctvDangerAreaConfig;
			else if (name == 'siteTrespassConfig') url = apiIndex.siteTrespassConfig.viewSiteTrespassConfig;
			else if (name == 'objectDangerOccurStatistic') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatistic;
			else if (name == 'objectDangerOccurStatisticBar') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticBar;
			else if (name == 'objectDangerOccurStatisticBar2') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticBar2;
			else if (name == 'objectDangerOccurStatisticLine2') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatisticLine2;
			else if (name == 'objectWarnWorkOccurStatisticBar') url = apiIndex.objectWarnWorkOccurStatistic.viewObjectWarnWorkOccurStatisticBar;
			else if (name == 'tree') url = apiIndex.tree.viewTree;
			else if (name == 'dashboard') url = apiIndex.dashboard.viewDashboard;
			else if (name == 'settingPushAlarm') url = apiIndex.settingPushAlarm.viewSettingPushAlarm;
			else if (name == 'speaker') url = apiIndex.speaker.viewSpeaker;
			else if (name == 'speakerSoundsrc') url = apiIndex.speakerSoundsrc.viewSpeakerSoundsrc;
			else if (name == 'speakerCctv') url = apiIndex.speakerCctv.viewSpeakerCctv;
			else if (name == 'registerUser') url = apiIndex.registerUser.viewRegisterUser;
			else if (name == 'cctvBySafeManager') url = apiIndex.cctvBySafeManager.viewCctvBySafeManager;
			else if (name == 'speakerAiConfig') url = apiIndex.speakerAiConfig.viewSpeakerAiConfig;
			else if (name == 'aiConfigDistigshSutbDgreCctv') url = apiIndex.aiConfigDistigshSutbDgreCctv.viewAiConfigDistigshSutbDgreCctv;
			else if (name == 'company') url = apiIndex.company.viewCompany;
			else if (name == 'grpCode') url = apiIndex.grpCode.viewGrpCode;
			else if (name == 'target') url = apiIndex.target.viewTarget;
			else if (name == 'exemplaryCase') url = apiIndex.exemplaryCase.viewExemplaryCase;
			else if (name == 'notice') url = apiIndex.notice.viewNotice;

			await this.$axios
				.post(url, {})
				.then(
					function (r) {
						if (r.data) {
							let data = r.data;
							if (data.token) delete data.token;

							// session clear 진행 시 commonCodeList까지 없어지는 것 방지
							const commonCodeList = this.commonCodeList;
							const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

							/**
							 * session storage 비우기 (session 데이터 계속 쌓이는것 방지)
							 * 뒤로가기 시 각 vue에서 main으로 이동되게 처리
							 * 뒤로가기 허용 시 sessionStorage.clear(); 삭제
							 *  */
							sessionStorage.clear();

							if (name == 'dashboard') {
								localStorage.clear();
								sessionStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								localStorage.setItem(name + 'PageParam', JSON.stringify(data));
								window.open(data.viewUrl);
							} else {
								sessionStorage.setItem('commonCodeList', JSON.stringify(commonCodeList));
								sessionStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								sessionStorage.setItem(name + 'PageParam', JSON.stringify(data));
								location.href = data.viewUrl;
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		async getCommCodeList() {
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			if (!commonCodeList || commonCodeList.length == 0) {
				await this.$axios
					.post(apiIndex.commCode.inqCommCodeAllList, {})
					.then(
						function (r) {
							sessionStorage.setItem('commonCodeList', JSON.stringify(r.data));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		logout() {
			// session storage 비우기
			sessionStorage.clear();
			// vue-cookies 제거
			VueCookies.remove('V2_ACCESS_TOKEN');
			location.href = '/login';
		},
		// 현장 변경
		changeSiteId() {
			const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

			// selectbox에 값에 필요한 현재 계정의 현장 정보를 조회
			this.$axios
				.post(apiIndex.login.inqChangeSiteIds, { userId: loginUserInfo.loginUserId, userRoleCd: loginUserInfo.userRoleCd })
				.then(
					function (r) {
						// // 입력한 아이디와 비밀번호가 일치하는 회원이면 response 받은 현장 정보를 저장
						if (r.data) {
							// 	// 셀렉트 박스 내용을 현재 로그인할 유저의 현장들로 세팅
							this.user.selectOption = r.data;
							if (this.user.selectOption.length > 1) {
								// 로그인 할 계정의 현장이 2개 이상이면 모달을 호출
								this.$refs.changeSiteModal.style.display = 'block';
							} else {
								return alert('변경할 현장이 존재하지 않습니다.');
							}
						} else {
							return alert('소속된 현장이 없습니다.\n관리자에게 문의해주시기 바랍니다.');
						}
					}.bind(this),
				)
				.catch(error => {
					alert(error.response.data.message);
				});
		},
		clickedChangeSite() {
			// 선택된 현장 값 세팅 후 form으로 넘기기
			if (this.user.selectSite == '') return alert('현장을 선택해 주십시오');

			// selectbox에서 선택한 siteId 값을 jwt에 저장하기 위해 controller 한번 더 호출
			this.$axios
				.post(apiIndex.login.setUserSiteId, { siteId: this.user.selectSite })
				.then(function (r) {
					window.location.href = r.data.redirectUrl;
					this.user.selectSite = '';
					this.$refs.changeSiteModal.style.display = 'none';
				})
				.catch(error => {
					alert(error.response.data.message);
				});
		},
		date() {
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			return (
				yyyy +
				'.' +
				this.addZero(mm) +
				'.' +
				this.addZero(dd) +
				'. ' +
				week[date.getDay()] +
				' ' +
				this.addZero(h) +
				':' +
				this.addZero(m) +
				':' +
				this.addZero(s)
			);
		},
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		handleGeoSucces() {
			this.$axios.post(mainUrl.getWeatherInfo, {}).then(r => {
				this.getWeather(r.data.latitude, r.data.longitude);
			});
		},
		handleGeoError() {
			console.log("Can't access geo location");
		},
		getWeather(lat, log) {
			const API_KEY = 'f42c8c69cb4e3987a7a8c8cc2eb15cc4';
			this.$axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${log}&appid=${API_KEY}&units=metric`).then(response => {
				const weatherData = response.data;

				// weatherData 데이터는 오늘을 포함한 총 8일 데이터가 들어가있음
				for (let i = 0; i < 7; i += 1) {
					const weatherDate = new Date(weatherData.daily[i].dt * 1000);
					const data = Object();
					data.dateWeekDay = this.weather.weekDayStrArr[weatherDate.getDay()]; // 요일
					data.month = this.addZero(String(weatherDate.getMonth() + 1));
					data.date = this.addZero(String(weatherDate.getDate()));
					data.icon = this.getWeatherIcon(weatherData.daily[i].weather[0].icon); // 날씨 아이콘
					data.temperatureMax = Math.round(weatherData.daily[i].temp.max); // 최고 기온
					data.temperatureMin = Math.round(weatherData.daily[i].temp.min); // 최소 기온
					this.weather.dateWeek.push(data);
				}
				this.weather.today.temperature = Math.round(weatherData.current.temp); // 온도
				this.weather.today.windSpeed = weatherData.current.wind_speed; // 풍속
				this.weather.today.windDeg = this.getWindDirection(weatherData.current.wind_deg); // 풍향
				this.weather.today.humidity = weatherData.current.humidity; // 습도
				this.weather.today.icon = this.getWeatherIcon(weatherData.current.weather[0].icon); // 날씨 icon
			});
		},
		getWeatherIcon(iconState) {
			switch (iconState) {
				case '01d':
					return '/dashboardlib/images/weather/sun.png';
				case '02d':
					return '/dashboardlib/images/weather/cloud-sun.png';
				case '03d':
					return '/dashboardlib/images/weather/cloud.png';
				case '04d':
					return '/dashboardlib/images/weather/cloud.png';
				case '09d':
					return '/dashboardlib/images/weather/cloud.png';
				case '10d':
					return '/dashboardlib/images/weather/umbrella.png';
				case '11d':
					return '/dashboardlib/images/weather/storm.png';
				case '13d':
					return '/dashboardlib/images/weather/snowflake.png';
				case '50d':
					return '/dashboardlib/images/weather/cloud.png';
				default:
					return '/dashboardlib/images/weather/sun.png';
			}
		},
		getWindDirection(degree) {
			if (degree > 337.5) return '북';
			if (degree > 292.5) return '북서';
			if (degree > 247.5) return '서';
			if (degree > 202.5) return '남서';
			if (degree > 157.5) return '남';
			if (degree > 122.5) return '남동';
			if (degree > 67.5) return '동';
			if (degree > 22.5) return '북동';
			return '북';
		},
		// 사용자 정보
		getUserInfo() {
			this.$axios
				.post(mainUrl.userInfo, {})
				.then(response => {
					if (response.data) {
						if (response.data.userInfo) {
							const userInfo = response.data.userInfo;
							this.user.name = userInfo.userNm;
						}

						if (response.data.siteInfo) {
							const siteInfo = response.data.siteInfo;
							this.user.site.latitude = siteInfo.latitude;
							this.user.site.longitude = siteInfo.longitude;
							this.user.site.name = siteInfo.siteNm;
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		reloadData() {
			this.getOccurEventCount();
			this.getCurrentSituationCount();
			this.getWorkReporting();
			this.getDangerOccurType();
			this.getRecentDangerOccur();
			this.getRecentlyNotice();
			this.getTodayWarnWorkOccur();

			for (let index = 0; index < this.jobSituationChart.classList.length; index++) {
				if (this.jobSituationChart.classList[index] == 'On') {
					switch (index) {
						case 0:
							this.loadDayChart();
							break;
						case 1:
							this.loadWeekChart();
							break;
						case 2:
							this.loadMonthChart();
							break;
						default:
							this.loadDayChart();
							break;
					}
				}
			}
		},
		// 위험 발생, 주의 발생, 조치 중 건 수
		getOccurEventCount() {
			this.$axios
				.post(mainUrl.occurEvent, {})
				.then(response => {
					if (response.data) {
						if (response.data.warnWorkDangerOccur) {
							const occurCount = response.data.warnWorkDangerOccur;
							this.currentSituationCount.dangerCount = occurCount.dangerOccurCnt; // 위험 발생
							this.currentSituationCount.warnWorkCount = occurCount.warnWorkOccurCnt; // 주의 발생
							this.currentSituationCount.actionCount = occurCount.takingActionCnt; // 조치 중
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 조치대기, 검토대기, 완료, 금일마감 건 수
		getCurrentSituationCount() {
			this.$axios
				.post(mainUrl.inqCurrentAccidentPrevent, {})
				.then(r => {
					const accidentCount = JSON.parse(JSON.stringify(r.data));
					this.currentSituationCount.requestCount = accidentCount.reqCnt; // 조치대기
					this.currentSituationCount.reviewCount = accidentCount.reviewCnt; // 검토대기
					this.currentSituationCount.completeCount = accidentCount.actnCnt; // 완료
					this.currentSituationCount.todayClosingcount = accidentCount.todayDelayCnt; // 금일마감
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 작업 보고
		getWorkReporting() {
			this.$axios
				.post(mainUrl.workReporting, {})
				.then(async r => {
					const data = r.data;

					let videoDangerActnDvsnCdOption = this.commonCodeList.filter(item => item.grpCd == 'video_danger_actn_dvsn_cd');
					const dangerOccurReportList = data.dangerOccurReportList;
					if (dangerOccurReportList && dangerOccurReportList.length > 0) {
						for (let i = 0; i < dangerOccurReportList.length; i++) {
							dangerOccurReportList[i].videoDangerActnDvsnNm = videoDangerActnDvsnCdOption.filter(
								item => item.value == dangerOccurReportList[i].videoDangerActnDvsnCd,
							)[0].text;
						}

						this.workReporting = dangerOccurReportList;
						let dangerOccurIndex = this.workReporting.findIndex(e => e.videoDangerActnDvsnNm == '위험 발생');
						this.recentDangerOccur.isNotEvent = dangerOccurIndex >= 0 ? false : true;
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 발생 위험유형
		getDangerOccurType() {
			this.$axios
				.post(mainUrl.dangerOccurType, {})
				.then(response => {
					if (response.data) {
						// 발생 위험 유형
						if (response.data.objectTargetDangerOccur) {
							const dangerOccurType = response.data.objectTargetDangerOccur;
							this.dangerOccurType.objectTargetDangerOccur = dangerOccurType.objectTargetDangerOccur;
							this.dangerOccurType.objectTargetDangerOccurTotal = dangerOccurType.objectTargetDangerOccurTotal;
							let total = this.dangerOccurType.objectTargetDangerOccurTotal;
							let list = this.dangerOccurType.objectTargetDangerOccur;
							if (total != 0) {
								let value = 100 / total;
								// 항목별 퍼센트 세팅(소수점 두자리)
								for (let i = 0; i < list.length; i++) {
									let percent = Math.round(list[i].objectTargetDangerCnt * value * 100) / 100;
									list[i].percent = percent + '%';
									list[i].barStyle = 'width: ' + percent + '%';
									if (percent > 30) list[i].classList = 'Red';
									else if (percent > 0) list[i].classList = 'Blue';
									else list[i].classList = 'Gray';
								}
							}
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 최근 위험 발생
		getRecentDangerOccur() {
			this.$axios
				.post(mainUrl.recentDangerOccur, {})
				.then(r => {
					const data = r.data;
					// 실시간 이벤트
					const dangerOccurRealtime = data.dangerOccurRealtime;
					this.recentDangerOccur.isLoad = true;
					if (dangerOccurRealtime != null) {
						this.recentDangerOccur.isNotEvent = false;
						this.recentDangerOccur.siteNm = dangerOccurRealtime.siteNm;
						this.recentDangerOccur.cctvInstallPlaceCont = dangerOccurRealtime.cctvInstallPlaceCont;
						this.recentDangerOccur.dangerOccurDtm = dangerOccurRealtime.dangerOccurDtm;
						this.recentDangerOccur.cctvNm = dangerOccurRealtime.cctvNm;
						this.recentDangerOccur.objectNm = dangerOccurRealtime.objectNm;
						this.recentDangerOccur.targetNm = dangerOccurRealtime.targetNm;
						this.recentDangerOccur.imgSaveFileNm = dangerOccurRealtime.imgSaveFileNm;

						this.$axios
							.post(mainUrl.readImage, { imgSaveFileNm: this.recentDangerOccur.imgSaveFileNm })
							.then(
								function (response) {
									if (response.data != null) {
										this.recentDangerOccur.img = 'data:image/jpg;base64,' + response.data.imgByte;
									}
								}.bind(this),
							)
							.catch(err => {
								console.log(err);
							});
					} else {
						this.recentDangerOccur.isNotEvent = true;
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 공지사항
		getRecentlyNotice() {
			this.$axios
				.post(mainUrl.inqRecentlyNotice, {})
				.then(r => {
					this.noticeInfo = r.data;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 금일 주의작업
		getTodayWarnWorkOccur() {
			this.$axios
				.post(mainUrl.todayWarnWorkOccur, {})
				.then(response => {
					if (response.data) {
						if (response.data.todayWarnWorkOccur) {
							this.todayWarnWorkOccur.length = response.data.todayWarnWorkOccur.length;
							this.todayWarnWorkOccur.data = response.data.todayWarnWorkOccur;
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 일 그래프
		loadDayChart() {
			this.$axios
				.post(mainUrl.inqDayChart, {})
				.then(response => {
					console.log(response);
					this.changeChartClickClass(0);
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 주 그래프
		loadWeekChart() {
			this.$axios
				.post(mainUrl.inqWeekChart, {})
				.then(response => {
					this.changeChartClickClass(1);
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 월 그래프
		loadMonthChart() {
			this.$axios
				.post(mainUrl.inqMonthChart, {})
				.then(response => {
					this.changeChartClickClass(2);
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 일, 주, 월 클릭 시 class 변경
		changeChartClickClass(index) {
			for (let index = 0; index < this.jobSituationChart.classList.length; index++) {
				if (this.jobSituationChart.classList[index] == 'On') {
					this.jobSituationChart.classList[index] = '';
				}
			}
			this.$set(this.jobSituationChart.classList, index, 'On');
		},
		// 차트 그리기
		drawChart() {
			console.log(this.jobSituationChart);
			$('#lineChart').kendoChart({
				dataSource: this.jobSituationChart.dataSource,
				categoryAxis: this.jobSituationChart.categoryAxis,
				valueAxis: this.jobSituationChart.valueAxis,
				series: this.jobSituationChart.series,
				legend: this.jobSituationChart.legend,
				tooltip: this.jobSituationChart.tooltip,
				chartArea: this.jobSituationChart.chartArea,
				plotArea: {
					color: 'green',
				},
			});
		},
		// 일/주/월 데이터 로드 후 차트에서 보여주기 위해 데이터 세팅
		createChartData(data) {
			let reqCntList = [];
			let reviewCntList = [];
			let actnCntList = [];
			let delayCntList = [];

			// 데이터 비우고 다시 세팅
			this.jobSituationChart.dataSource = [];
			data.forEach(d => {
				this.jobSituationChart.dataSource.push({ reqDtm: d.reqDtm });
				reqCntList.push(d.reqCnt);
				reviewCntList.push(d.reviewCnt);
				actnCntList.push(d.actnCnt);
				delayCntList.push(d.delayCnt);
			});

			let reqCnt = {
				type: 'line',
				data: reqCntList,
				name: '조치대기',
				color: '#0261cc',
				markers: {
					visible: true,
					background: function (e) {
						return e.series.color;
					},
				},
			};

			let reviewCnt = {
				type: 'line',
				data: reviewCntList,
				name: '검토대기',
				color: '#07c4ee',
				markers: {
					visible: true,
					background: function (e) {
						return e.series.color;
					},
				},
			};

			let actnCnt = {
				type: 'line',
				data: actnCntList,
				name: '완료',
				color: '#1fb07d',
				markers: {
					visible: true,
					background: function (e) {
						return e.series.color;
					},
				},
			};

			let delayCnt = {
				type: 'line',
				data: delayCntList,
				name: '마감지연',
				color: '#fdc16d',
				markers: {
					visible: true,
					background: function (e) {
						return e.series.color; // will match the series color
					},
				},
			};

			// 데이터 비우고 다시 세팅
			this.jobSituationChart.series = [];
			this.jobSituationChart.series.push(reqCnt);
			this.jobSituationChart.series.push(reviewCnt);
			this.jobSituationChart.series.push(actnCnt);
			this.jobSituationChart.series.push(delayCnt);

			this.drawChart();
		},
		clickedCnt(kind) {
			let param = { kind: kind };
			// 권한 체크
			const accidentPre = $('a[data-desc="재해예방조치"]');
			if (accidentPre && accidentPre.length > 0) {
				// 기존 pageParam 내용 불러오기
				this.$axios
					.post(accidentPreUrl.viewAccidentPrevent, new URLSearchParams(param))
					.then(
						function (r) {
							if (r.data.token) delete r.data.token;
							sessionStorage.setItem('accidentPreventPageParam', JSON.stringify(r.data));
							location.href = '/accident-prevent';
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// 위험발생/주의발생 n건 클릭 시 화면 이동
		async clickedRedirectionOccur(e, eventType) {
			const { data } = await this.$axios.post(apiIndex.dangerWarnWorkOccur.viewDangerWarnWorkOccur, {});
			sessionStorage.setItem('dangerWarnWorkOccurPageParam', JSON.stringify({ ...data, eventType }));
			location.href = data.viewUrl;
		},
		showNoticeDetail(index) {
			if (!this.noticeInfo[index] || !this.noticeInfo[index].noticeId) {
				return alert('공지사항 정보가 올바르지 않습니다');
			}

			// 팝업창 가로/세로크기
			let w = 600;
			let h = 600;

			let url = 'recently-notice?notice_id=' + this.noticeInfo[index].noticeId;
			let title = 'recently-notice-popup';

			var left = screen.width / 2 - w / 2;
			var top = screen.height / 2 - h / 2;
			return window.open(
				url,
				title,
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
					w +
					', height=' +
					h +
					', top=' +
					top +
					', left=' +
					left,
			);
		},
		curEventFrames() {
			let dangerOccurIndex = this.workReporting.findIndex(e => e.videoDangerActnDvsnNm == '위험 발생');
			this.showDangerReportDetail(this.workReporting[dangerOccurIndex], dangerOccurIndex);
		},
		showDangerReportDetail(item) {
			this.dangerOccurDetailsItem = item;
			// 상세 팝업 표시
			this.showDetailsPopup = true;
		},
		stopScrollingByKeydown(e) {
			if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
				e.preventDefault();
			}
		},
		//모달창에서 키 다운으로 이전 이미지로 이동
		previousImage() {
			if (this.activeImage > 0) {
				this.activeImage = this.activeImage - 1;
				this.selectPicture(this.activeImage);
			}
		},
		//모달창에서 키 다운으로 다음 이미지로 이동
		nextImage() {
			if (this.activeImage < this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length - 1) {
				this.activeImage = this.activeImage + 1;
				this.selectPicture(this.activeImage);
			}
		},
		//모달창에서 이미지를 선택했을 때
		selectPicture(index) {
			if (index < 0 || index >= this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length) {
				return;
			}
			this.activeImage = index;
			let lastIndexOfFrame = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length - 1;
			let endFrameImgSeq = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[lastIndexOfFrame].frameImgSeq;

			this.popupImg.imgSaveDtm = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activeImage].imgSaveDtm;
			this.$nextTick(function () {
				document.querySelector('.activeImage').parentElement.focus();
				if (index % 4 == 0) {
					if (this.scrollDirection == 'down') {
						document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
					} else if (this.scrollDirection == 'up') {
						document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
					}
				}
				this.inqImgDetailsInfo(endFrameImgSeq);
			});

			// let distance = (index - 10) * 10;
			// $('.modal-content [left]').animate({ scrollTop: index * 130 - 170 + distance }, 50);
		},
		inqImgDetailsInfo(endFrameImgSeq) {
			this.$axios
				.post(dangerOccurUrl.inqImgDetailsInfo, {
					siteId: this.dangerOccurDetailsItem.siteId,
					cctvId: this.dangerOccurDetailsItem.cctvId,
					frameImgSeq: this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activeImage].frameImgSeq,
					endFrameImgSeq: endFrameImgSeq,
				})
				.then(
					function (r) {
						let objects = [];
						if (r.data != '') {
							r.data.forEach(e => {
								let objectAndTarget = {};
								this.$set(objectAndTarget, 'objectNm', e.objectNm);
								this.$set(objectAndTarget, 'targetNms', e.targetNms);
								objects.push(objectAndTarget);
							});
						}
						this.popupImg.objects = objects;
						this.forRerendering += 1;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		popupImgClose() {
			// img popup 닫기
			this.$refs.popupImg.style.display = 'none';
			this.activeImage = 0;
			window.removeEventListener('keydown', this.stopScrollingByKeydown);
		},
		setDetailsItem(data, selectedRowItem) {
			this.dangerOccurDetailsItem = $.extend(true, {}, data);
			this.dangerOccurDetailsItem.dangerOccurDtm = selectedRowItem.dangerOccurDtm;
			this.dangerOccurDetailsItem.dangerLiftDtm = selectedRowItem.dangerLiftDtm;
			this.dangerOccurDetailsItem.objectId = selectedRowItem.objectId;
			this.dangerOccurDetailsItem.targetId = selectedRowItem.targetId;
			this.dangerOccurDetailsItem.dangerOccurObjectSeq = selectedRowItem.dangerOccurObjectSeq;
			this.dangerOccurDetailsItem.videoDangerActnDvsnCd = selectedRowItem.videoDangerActnDvsnCd;
			this.dangerOccurDetailsItem.actnReasonCont = selectedRowItem.actnReasonCont;
			this.dangerOccurDetailsItem.strtFrameImgSeq = selectedRowItem.strtFrameImgSeq;
			this.dangerOccurDetailsItem.obejctNm = selectedRowItem.obejctNm;
			this.dangerOccurDetailsItem.targetNm = selectedRowItem.targetNm;
			this.dangerOccurDetailsItem.cctvNm = selectedRowItem.cctvNm;
			this.dangerOccurDetailsItem.cctvInstallPlaceCont = selectedRowItem.cctvInstallPlaceCont;
		},
		//이미지 데이터 세팅
		setAllFramesToImageData() {
			this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.forEach(e => {
				this.$axios.post(dangerOccurUrl.imgOneDangerOccur, { fileName: e.imgSaveFileNm }).then(response => {
					e.imgData = 'data:image/jpg;base64,' + response.data.imgByte;
				});
			});
		},
		onCloseDetailsPopup() {
			this.showDetailsPopup = false;
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
.mng_menu {
	width: 166px !important;
	background: #000000;
}

.Wrap .Header .Nav .Nav_Sub_Multiple {
	position: absolute;
	width: 100%;
	left: 0px;
	z-index: 99;
	top: 60px;
	display: none;
}
.Wrap .Header .Nav .Nav_Sub_Multiple ul > li:first-child {
	background: #35363b;
}
.Wrap .Header .Nav ul li:hover .Nav_Sub_Multiple {
	display: flex !important;
	width: 100% !important;
}

.Wrap .Container .Ri_Cons .Ri_Cons_NoticeCnt {
	display: flex;
	padding: 20px;
}

.Wrap .Container .Ri_Cons .Ri_Cons_NoticeCnt > div {
	flex: 1;
}

.Wrap .Container .Ri_Cons .Ri_Cons_NoticeCnt > div:last-child {
	border: 0;
	margin: 0;
	padding-right: 0;
}
</style>
